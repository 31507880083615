.footer{
    margin-top: 50px;
 width: 100%;
 background-color: #232324;

}

.footer_container{
    width: 80%;
    padding: 50px 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.footer_container ul{
    margin-bottom: 0;
    padding-left: 0;
    line-height: 40px; 
    list-style: none;
}
.footer_container ul li a{
    color: white;
    text-decoration: none;
    position: relative;
    font-size: 15px;
    letter-spacing: 1px;
}
.footer_container ul li a::before{
    content: "\f101";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
}
.footer_content{
    width: 33.33%;
}
.footer_content h3{
    color: white;
    font-size: 18px !important;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.footer_content p{
    color: white;
}
.footer .copyrights {
    color: white;
    border-top: solid gray 2px;
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    letter-spacing: 1px;
}

@media screen and (max-width:500px) {
    .footer_container {
        flex-direction: column;
        gap: 30px 0;
    }
    .footer_content {
        width: 100%;
    }
}