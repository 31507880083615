.home{
    min-height: 3000px;
}

.home .badges{
    display: flex;
    justify-content: space-evenly;
    background-color: var(--blue);
    margin: 50px ;
    padding: 20px 60px;
}

.home .badges .badge{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home .badges .badge .icon{
    width: 40%;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.432));
}
.home .badges .badge h4{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}
.home .badges .badge p{
    font-weight: 500;
    font-size: 15px;
}

.home .badges .badge .arrow{
    width: 30px;
}
.product_categories{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    gap:30px 0;
    justify-content: space-between;

}
#viewmore_btn{
    width: fit-content;
    margin: auto;
}
.product_categories h3{
    width: 100%;
    text-align: center;
    font-weight: 600;

}
.product_categories ._product{
width: 27%;
background-color: white;
background-color:  #f4f4f4;
padding: 30px;
border-radius: 5px;
box-shadow: 0 0 5px rgba(128, 128, 128, 0.466);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.our_products{
    width: 23% !important;
    position: relative;
}
.our_products:before{
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    content: "";

}
.product_categories ._product h4{
    font-size: 16px;
    text-align: center;
    font-weight: 600;
 
    margin: 10px 0;
    letter-spacing: 1px;
}
.product_categories ._product button,.allcategory_btn button{
    background-color: var(--blue);
    color: white;
    padding: 5px 20px;
    border: none;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.418);
    border-radius: 5px;
    transition: 0.4s all;
}
.product_categories ._product button:hover{
    transform: scale(1.075);
    transition: 0.4s all;
}

.about_us{
    margin: 50px 0;
    width: 100%;
    min-height: 600px;
    background-image: url('../Assets/about\ us\ banner.jpg');
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    align-items: center;
}
.about_container{
    width: 40%;
    color: white;
    position: relative;
    left: 100px;
    line-height: 35px;
}
.about_container h4{
    font-size: 30px;
    font-weight: 700;
}
.about_container button{
    background-color: var(--blue);
    color: white;
    padding: 0px 20px;
    border: none;
    border-radius: 5px;
}
.view_more{
   background-color: var(--blue);
   color: white;
   border: none;
   padding: 5px 20px;
   border-radius: 3px;
}
.whatsapp{
    position: fixed;
    bottom:25px;
    left: 25px;
    width: fit-content;
    z-index: 1500;
}
.whatsapp img{
    width: 70px;
    animation: whatsapp 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes whatsapp {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.125);
    }
}
.allcategory_btn{
    max-width: fit-content;
    margin: auto;
    margin-top: 50px;
}
.why_choose{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 50px;
}
.why_choose h3{
    min-width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    text-transform: uppercase;
}
.why_choose .image{
    width: 30%;
}
.why_choose .content{
    width: 70%;
    padding: 0 15px;
}
.why_choose .content p span{
    font-weight: 600;
}
.our_products h4{
    height: 20px;
}

@media screen and (max-width:500px) {
    .why_choose{
        width: 95%;
    }
    .why_choose h3{
        min-width: 80%;
        width: 80%;
        margin: auto;
    }
    .why_choose .image{
        width: 100%;
    }
    .why_choose .content{
        width: 100%;
        padding: 0 15px;
        text-align: justify;
        margin-top: 15px;
    }
    .why_choose .content p span{
        font-weight: 600;
    }
    .home .badges {
        /* display: flex; */
   flex-direction: column;
        gap: 20px 0;
        background-color: white;
        margin: 30px 15px;
        padding: 20px 0px;
    }
    .home .badges .badge {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--blue);
    }
    .product_categories ._product {
        width: 100%;
    }
    .about_container {
        width: 90%;
        margin: auto;
        left: 0px;
    }
    .product_categories ._product {
        width: 100% !important;
        background-color: white;
        background-color: #f4f4f4;
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(128, 128, 128, 0.466);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}