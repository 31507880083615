.navbar-brand img{
    width: 80px;
}
.navbar .container-fluid{
    display: flex;
    justify-content: space-between;
}
#navbarSupportedContent>ul>li{
    margin-right: 35px;
}
#navbarSupportedContent ul li a{
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    text-transform: uppercase;
}
#navbarSupportedContent button{
    font-weight: 500;
background-color: var(--blue);
color: white;
}
nav{
    border-bottom: solid var(--blue) 2px;
    padding: 0 !important;
}
.dropdown-menu{
    font-size: 14px;
    width: fit-content;
}
.view_all{
    font-size: 12px;
    color: var(--blue) !important;
}

.dropdown-menu li:hover a{
    color: white !important;
    background-color: var(--blue) !important;

}
@media screen and (max-width:500px) {
    .navbar-collapse{
        padding-bottom: 20px;
        align-items: center !important;
        flex-direction: column !important;
    }
    .navbar-collapse>ul {
        gap: 10px 0;
    }
    .navbar-collapse>ul>li{
        width: 100%;
        text-align: center;
        margin: auto !important;
        box-shadow: 0 0 3px gray;
    }
    .navbar-collapse ul li:last-child{
        box-shadow: none !important;
    }
    .navbar-collapse ul li ul{
        width: 100% !important;
        text-align: center;
    }
    #contactBtn{
        width: fit-content;
        margin: auto !important;
        margin-top: 15px !important;
    }
}