@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  margin: 0;
  padding: 10;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
scroll-behavior: smooth;
}
body{
  /* background-color: rgb(224, 224, 224) !important; */
}
:root{
  --blue:#da9700;
}
.for_seo{
  display: none;
}
