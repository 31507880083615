.about_page .banner{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('../Assets/abou_page\ banner.jpg');
    background-size: cover;
}
.about_page .banner h2{
    font-size: 45px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}

main{
    width: 80%;
    margin: auto;
}

.about_page main .main_content{
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.about_page main .main_content p{
    width: 70%;
}
.about_page main .main_content .logo_image{
    width: 30%;
}

.qa{
margin-top: 50px;
}
.qa h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}
.qa p span{
    font-weight: 600;
}
.brochure{
    width: fit-content;
    margin: auto;
    margin-top: 30px;
}
.brochure button{
    background: var(--blue);
    color:white;
    border: none;
    box-shadow: 0 0 3px gray;
    border: none;
    border-radius: 5px;
    padding: 5px 25px;
}
.brochure button i{
    margin-right: 10px;
}

@media screen and (max-width:500px) {
    main {
        width: 90%;
    }
    .about_page main .main_content {
        display: flex;
        flex-direction: column;
        gap: 30px 0;
    }
    .about_page main .main_content p {
        width: 100%;
        order: 2;
        text-align: justify;
    }
    .about_page main .main_content .logo_image {
        width: 60%;
        order: 1;
    }
    .about_us_content{
        text-align: justify;
    }
    .qa p {
        text-align: justify;
    }
}