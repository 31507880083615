.contact_page .banner{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('../Assets/contact\ banner.jpg');
    background-size: cover;
}
.contact_page .banner h2{
    font-size: 45px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}

.contact_badges{
    width: 80%;
    display: flex;
    justify-content: space-between;

    margin: 50px auto;
}

.contact_badges ._badge{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(212, 212, 212);
    padding: 20px;
    height: 100%;
}

.contact_badges ._badge i{
    font-size: 45px;
    opacity: 80%;

}

.contact_badges ._badge h4{
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    opacity: 80%;

}

.contact_badges ._badge p{
    opacity: 80%;
}
.contact_container{
    display: flex;
}
.contact_container>div{
    width: 50%;
}
.contact_form{
    padding: 50px 45px;
    background-image: url('../Assets/conts.jpg');
    background-color: cover;
    width: 100%;
}
.contact_form form{
    display: flex;
    flex-direction: column;
    gap: 15px 0;
}
.contact_form form h4{
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.contact_form form input,.contact_form form textarea{
    padding: 10px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 3px black;
}
.contact_form form textarea{
    min-height: 150px;
    max-width: 100%;
}
#btn{
    width: fit-content;
    min-width: 300px;
    background: var(--blue);
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 5px 10px !important;
}
.error{
    color: rgb(253, 32, 32);
    font-size: 14px;
    padding: 0 10px;
    background-color: white;
    border-radius: 3px;
}

@media screen and (max-width:500px) {
    .contact_badges{
        flex-direction: column !important;
        gap: 10px 0;
        width: 90%;
    }
    .contact_badges ._badge {
        min-width: 100% !important;
        
    }
    .contact_container{
        flex-direction: column;
        gap: 15px 0;
    }
    .contact_container>div{
        width: 100%;
    }
    .contact_container .map{
        order: 2;
        height: 350px;
    }
    

}