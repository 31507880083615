.product_page .banner{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('../Assets//product_banner.jpg');
    background-size: cover;
}
.product_page .banner h2{
    font-size: 45px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}
.product_container{
    width: 100%;
    margin:50px 0px;
    display: flex;
}
.product_container div{
    margin: 10px;
    box-shadow: 0 0 3px gray;
}
.product_container .sidebar{
    width: 20%;
    background-color: white;

}
.product_container .sidebar h5{
    margin-bottom: 0;

}

.product_container .sidebar ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.product_container .sidebar ul h5{
    padding: 10px;
    background-color: rgba(128, 128, 128, 0.514);
    font-weight: 600;
}
.product_container .sidebar ul li{
    padding: 10px;
    border-bottom: solid rgba(0, 0, 0, 0.342) 1px;
    cursor: pointer;
}
.product_container .sidebar ul li a{
    color: black;
    text-decoration: none;
    opacity: 70%;
    font-weight: 600;
    transition: 0.3s all;
}
.product_container .sidebar ul li a:hover{
    margin-left: 10px;
    transition: 0.3s all;
}

.product_container .main_container{
    width: 80%;
    background-color: white;
}
.product_container .main_container h3{
    padding: 10px;
    text-transform: uppercase;
    font-size: 20px;
}
.main_container section {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap:20px;
align-content: flex-start;
  }
.main_container .product{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(25% - 15px);
    margin: 0px !important;
    height: fit-content;
    padding-bottom: 10px;
}
.main_container .product img{
    width: 100%;
}
.main_container .product p{
    height: 25px;
    text-align: center;
    font-size: 13px;
    line-height: 15px;
    margin: 10px 0;
}
.main_container .product button{
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 3px 25px;
    box-shadow: 2px 2px 3px gray;
    font-size: 14px;
    transition: 0.3s all;
}
.main_container .product button:hover{
    transform: scale(1.1);
    transition: 0.3s all;
}

.booking_container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index:-0.5;
}
.booking_container .dummy{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

.booking_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(51, 51, 51);
    padding: 30px;
    border-radius: 5px;
    gap: 15px;
    position: relative;
    z-index: 100;
    animation: popup 0.3s;
}
@keyframes popup {
    0%{
        transform: scale(0.5);
    }
    50%{
        transform: scale(1.125);
    }
    100%{
        transform: scale(1);
    }
}
.booking_box input{
    width: 300px;
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 3px gray;
    font-size: 14px;
}
.booking_box input::placeholder{
font-size: 14px;
}
.booking_box button{
    background-color: var(--blue);
    color: white;
    border: none;
    box-shadow: 0 0 15px black;
    padding: 3px 20px;
    border-radius: 5px;
    margin-top: 15px;
}
.booking_box i{
    position: absolute;
    top: -5px;
    right: -5px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
#productDropdownIcon{
    display: none;
}

@media screen and (max-width:500px) {
    .product_container{
        flex-direction: column;
        width: 90%;
        margin:30px auto;
    }
    .product_container .sidebar{
        width: 100%;
        margin: 0;
    }
    .product_container .sidebar ul li{
        display: none;
    
    }
    .product_container .main_container {
        width: 100%;
        background-color: white;
        margin: 20px 0;
    }
    .product_container .sidebar h5{
        display: flex;
        justify-content: space-between;
    }
    #productDropdownIcon{
        display: block;
    }
    .main_container .product {
        width: 100%;
    }
    .main_container .product p {
        height: unset;
        font-size: 16px;
        margin: 15px 0;
    }
    .product_container .main_container h3 {
       font-weight: 600;
    }
    .main_container .product button {
        font-size: 16px;
        font-weight: 600;
    }
}